#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #cddada73;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
