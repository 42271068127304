.dnd__titles {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.dnd__wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  min-height: 40vh;
  max-height: 80vh;

  .draggable-item {
    transform: scale(0.97);
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.0627451);

    &:hover {
      transform: scale(1);
      box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.12549);
    }
  }
}
