.home {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    height: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 1300px;
    margin: 0 auto;
  }

  &__header {
    box-shadow: 7px 2px 15px 3px #00000069;
    &--benefits {
      background-color: #0072c0bd;
    }
    &--barriers {
      background-color: #e94025d6;
    }
    &-wrapper {
      display: flex;
      align-items: center;
      max-width: 1300px;
      margin: 0 auto;
      padding: 10px;
    }

    &-title {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    &-input {
      border: 1px solid transparent;
      background-color: transparent;
      border-radius: 6px;
      transition: 0.2s ease-in;
      cursor: pointer;
      font-size: 2rem;
      color: black;

      &:hover {
        border: 1px solid #00000059;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: black;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: black;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: black;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: black;
      }
    }

    a {
      color: black;
      transition: 0.2s ease-in;
      font-size: 2em;

      &:hover {
        color: #0000008a;
      }
    }
  }

  &__description {
    width: 58%;
    margin: 0 auto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    .ant-btn {
      padding: 0 75px;
      display: inline-block;
    }

    .button-next {
      margin-left: auto;
    }
  }

  // Title page
  &__preview {
    height: 100%;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding: 10px;
    justify-content: space-between;

    &-header {
      display: flex;
      justify-content: space-between;
      a {
        color: black;
        transition: 0.2s ease-in;
        font-size: 2em;

        &:hover {
          color: #0000008a;
        }
      }
    }
    &-title-wrapper {
      display: flex;
      align-items: center;

      .ant-input {
        width: 300px;
      }
    }
    &-title {
      margin-bottom: 0;
      margin-right: 15px;
    }
  }

  // Pdf document
  &__document-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
  }

  &__documents-link {
    margin: 20px 0;
  }
}
