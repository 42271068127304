.document {
  max-width: 1300px;
  width: 100%;
  padding: 20px;

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__box {
    margin-bottom: 20px;
    text-align: center;
    box-shadow: 2px 0px 7px grey;
    background-color: white;
    padding: 15px 10px;
    max-width: 300px;
    width: 100%;

    p {
      margin: 0;
    }

    &-title {
      color: black;
    }
  }
}
