.c-form__wrapper {
  display: flex;
  flex: 1 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.c-form {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 60px 40px;
  border-radius: 6px;
  box-shadow: #3f51b533 0px 0px 12px 3px;
  text-align: center;

  .ant-form-item {
    margin-right: 0;
    margin-bottom: 7px;
  }

  .ant-form-item-control-wrapper,
  &-button {
    width: 100%;
  }
}
